import React from "react";
import TungstunPage from "./../../stories/page/tungstun-page";
import TungstunTitle from "./../../stories/title/tungstun-title";

const PrivacyPage = ({}) => {
  return (
    <TungstunPage>
      <TungstunTitle back text={"📖 Privacy statement"} level={1} />
      <p style={{textAlign: "justify"}}>
        Tungstun, gevestigd aan Wereldtuinenlaan 49 Vleuten Nederland, is
        verantwoordelijk voor de verwerking van persoonsgegevens zoals
        weergegeven in deze privacyverklaring. Contactgegevens:
        https://www.tungstun.nl Wereldtuinenlaan 49 Vleuten Nederland +31 6
        58924036 Persoonsgegevens die wij verwerken Tungstun verwerkt uw
        persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u
        deze zelf aan ons verstrekt. Hieronder vindt u een overzicht van de
        persoonsgegevens die wij verwerken: - Voor- en achternaam -
        Telefoonnummer - E-mailadres Bijzondere en/of gevoelige persoonsgegevens
        die wij verwerken Onze website en/of dienst heeft niet de intentie
        gegevens te verzamelen over websitebezoekers die jonger zijn dan 16
        jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter
        niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan
        ook aan betrokken te zijn bij de online activiteiten van hun kinderen,
        om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder
        ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die
        toestemming persoonlijke gegevens hebben verzameld over een
        minderjarige, neem dan contact met ons op via jort@tungstun.nl, dan
        verwijderen wij deze informatie. Met welk doel en op basis van welke
        grondslag wij persoonsgegevens verwerken Tungstun verwerkt uw
        persoonsgegevens voor de volgende doelen: - U te kunnen bellen of
        e-mailen indien dit nodig is om onze dienstverlening uit te kunnen
        voeren - U de mogelijkheid te bieden een account aan te maken
        Geautomatiseerde besluitvorming Tungstun neemt #responsibility op basis
        van geautomatiseerde verwerkingen besluiten over zaken die
        (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om
        besluiten die worden genomen door computerprogramma's of -systemen,
        zonder dat daar een mens (bijvoorbeeld een medewerker van Tungstun)
        tussen zit. Tungstun gebruikt de volgende computerprogramma's of
        -systemen: Bartap API Hoe lang we persoonsgegevens bewaren Tungstun
        bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen
        te realiseren waarvoor uw gegevens worden verzameld. Wij hanteren de
        volgende bewaartermijnen voor de volgende (categorieën) van
        persoonsgegevens: 2 jaar (persoonsgegevens binnen Bartap API) Delen van
        persoonsgegevens met derden Tungstun deelt uw persoonsgegevens met
        verschillende derden als dit noodzakelijk is voor het uitvoeren van de
        overeenkomst en om te voldoen aan een eventuele wettelijke verplichting.
        Met bedrijven die u gegevens verwerken in onze opdracht, sluiten wij een
        verwerkersovereenkomst om te zorgen voor eenzelfde niveau van
        beveiliging en vertrouwelijkheid van uw gegevens. Tungstun blijft
        verantwoordelijk voor deze verwerkingen. Daarnaast verstrekt Tungstun uw
        persoonsgegevens aan andere derden. Dit doen wij alleen met uw
        nadrukkelijke toestemming. Op dit moment deelt Tungstun geen
        persoonsgegevens met derden. Cookies, of vergelijkbare technieken, die
        wij gebruiken Tungstun gebruikt alleen technische en functionele
        cookies. En analytische cookies die geen inbreuk maken op uw privacy.
        Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
        website wordt opgeslagen op uw computer, tablet of smartphone. De
        cookies die wij gebruiken zijn noodzakelijk voor de technische werking
        van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar
        behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook
        kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor
        cookies door uw internetbrowser zo in te stellen dat deze geen cookies
        meer opslaat. Daarnaast kunt u ook alle informatie die eerder is
        opgeslagen via de instellingen van uw browser verwijderen. Gegevens
        inzien, aanpassen of verwijderen U heeft het recht om uw
        persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast
        heeft u het recht om uw eventuele toestemming voor de gegevensverwerking
        in te trekken of bezwaar te maken tegen de verwerking van uw
        persoonsgegevens door Tungstun en heeft u het recht op
        gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt
        indienen om de persoonsgegevens die wij van u beschikken in een
        computerbestand naar u of een ander, door u genoemde organisatie, te
        sturen. U kunt een verzoek tot inzage, correctie, verwijdering,
        gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking
        van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens
        sturen naar jort@tungstun.nl. Om er zeker van te zijn dat het verzoek
        tot inzage door u is gedaan, vragen wij u een kopie van uw
        identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw
        pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het
        paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter
        bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen
        vier weken, op uw verzoek. Tungstun wil u er tevens op wijzen dat u de
        mogelijkheid heeft om een klacht in te dienen bij de nationale
        toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende
        link:
        https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
        Hoe wij persoonsgegevens beveiligen Tungstun neemt de bescherming van uw
        gegevens serieus en neemt passende maatregelen om misbruik, verlies,
        onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging
        tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd
        zijn of er zijn aanwijzingen van misbruik, neem dan contact op met onze
        klantenservice of via jort@tungstun.nl. Tungstun heeft de volgende
        maatregelen genomen om uw persoonsgegevens te beveiligen: - TLS
        (voorheen SSL) Wij versturen uw gegevens via een beveiligde
        internetverbinding. Dit kunt u zien aan de adresbalk 'https' en het
        hangslotje in de adresbalk. - Beveiligde API door middel van JWT en
        Spring Security.
      </p>
    </TungstunPage>
  );
};

export default PrivacyPage;
